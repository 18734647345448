import LaunchIcon from "@mui/icons-material/Launch";
import type { LinkProps } from "@mui/material";
import MuiButton, { ButtonProps } from "@mui/material/Button";
import { styled } from "@mui/material/styles";

const StyledButton = styled(MuiButton)(({ theme }) => ({
  fontSize: theme.typography.caption.fontSize,
  padding: theme.spacing(0.25, 2),
  [theme.breakpoints.down(`sm`)]: {
    padding: theme.spacing(0.25, 1),
  },
}));

export const Link = (props: ButtonProps & { target: LinkProps[`target`] }) => {
  return (
    <StyledButton variant="outlined" endIcon={<LaunchIcon />} {...props} />
  );
};
