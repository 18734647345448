const MMM_DD_YYYY_Option: Intl.DateTimeFormatOptions = {
    year: `numeric`,
    month: `short`,
    day: `2-digit`,
};

const MMM_DD_Option: Intl.DateTimeFormatOptions = {
    month: `short`,
    day: `2-digit`,
};

export const pathToExternalUrl = (path: string | undefined) => {
    window.open(path, `_blank`);
};

const formatDateToUSLocaleFromDateObj = (date: Date) => {
    return date.toLocaleString(`en-US`, MMM_DD_Option);
};

export const getCurrentYear = (): number => {
    const year = new Date().getFullYear();
    return year;
};

const thousandSeparatorParser = (str: string | number): string => {
    const numberFormatter = Intl.NumberFormat(`en-US`);
    const formatted = numberFormatter.format(Number(str));
    return formatted;
};

export const MetricsUtils = {
    formatDateToUSLocaleFromDateObj,
    thousandSeparatorParser,
    pathToExternalUrl,
    getCurrentYear
};
