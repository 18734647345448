import { Box, Grid, Typography, useTheme } from "@mui/material";
import HashpackLogo from "assets/imgs/sponsors/hashpack.png";
import { hashpackLink } from "constants/links";

export const Sponsors = () => {
    const theme = useTheme();
    return (
        <Grid container xs={12} md={12} >
            <Box
                width='100%'
                display="flex"
           
            >
            <Box m="auto">
                <Box sx={{ paddingBottom: theme.spacing(1)}}>
                    <Typography display="flex" justifyContent="center" color={theme.palette.grey[400]} variant="caption">sponsored by</Typography>
                </Box>
                <a
                    href={hashpackLink}
                    target="_blank"
                    style={{ display: `flex`, alignItems: `center` }}
                    rel="noreferrer"
                >
                   <img
                    src={HashpackLogo}
                    alt="Hashpack Logo"
                    style={{  maxWidth: 130, objectFit: `contain`, opacity:0.7 }}
                    />
                </a>
            </Box>
        </Box>
        </Grid>
    );
};
